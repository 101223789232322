import { createStore } from 'vuex'
import * as icons from "@element-plus/icons-vue";
import * as Api from "@/utils/api/core";
import * as DB from "@/utils/DB";
import Construction from "@/views/construction.vue";
import { RouteRecordRaw } from "vue-router";
import router, { routes, pageRoutes } from "@/router";
import View from '@/views/index.vue'

import { getCompletePath } from "@/utils/tool";

import PathMap from '@/router/PathMap';

type PathMapType = keyof typeof PathMap

// $store.state.system.labelPosition

// & / 850 / 680
type SizeType = 'small' | 'medium' | 'large'
interface RouteDataType {
  path: string;
  label: string;
  icon: keyof typeof icons;
  children: RouteDataType[];
}

interface Store {
  system: {
    width: number,
    size: SizeType
  },
  currentPath: string,
  TerrData: TreeType[],
  pageRoutes: any[]
  userInfo: UserInfoType,
}

const sizeConfig = {
  small: 680,
  medium: 850
}
const page404 = {
  meta: {
    isFaultMenuView: true,
    title: "404"
  },
  path: '/:pathMatch(.*)*',
  name: 'NotFound',
  component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
}

function createRouterList(list: RouteDataType[], path: string = '/'): Array<RouteRecordRaw> {
  return list.map((item) => {
    const completePath = getCompletePath(item.path, path);
    const component = item.children?.length ? View : (PathMap[completePath as PathMapType]) ?? Construction
    const arr = item.path.split('/')
    const name = arr[arr.length - 1];
    return {
      path: item.path,
      meta: {
        title: item.label,
        icon: item.icon ? icons[item.icon] : null,
      },
      component,
      children: item.children?.length ? createRouterList(item.children, completePath) : [],
    };
  });
}


export default createStore<Store>({
  state: {
    system: {
      width: window.innerWidth,
      size: 'large',
    },
    currentPath: location.pathname,
    TerrData: [],
    pageRoutes: [],
    userInfo: {
      avatarUrl: '',
      createTime: '',
      email: '',
      id: 0,
      mobile: '',
      nickname: '',
      registerTime: '',
      roleList: [],
      status: "NORMAL",
      updateTime: '',
      token: ''
    },
  },
  mutations: {
    setSystem(state) {
      const w = window.innerWidth;
      state.system.width = w;
      state.system.size = w > sizeConfig.small
        ? (w > sizeConfig.medium ? 'large' : 'medium')
        : 'small';
    },
    async setPageRoutes(state) {
      const userInfo = DB.getUserInfoDB();
      if (!userInfo.id || !userInfo.token) return;
      const res = await Api.getRouterListApi(userInfo.id);
      if (res.code === 0) {
        const routerArr = [...routes];
        const list = [...createRouterList(res.data), page404];
        {
          let i = 0;
          while (i < list.length) {
            const route = list[i];
            try {
              router.addRoute(route);
              routerArr.push(route)
            } catch (error) {
              console.log(error)
              list.push(...process.env.NODE_ENV === "development" ? pageRoutes : [])
            }
            i++;
          }
        }
        setTimeout(() => {
          DB.setRouterDB(routerArr);
          state.pageRoutes = routerArr;
          router.replace(router.currentRoute.value.path)
        });
      }
    },
    setUserInfo(state, data: UserInfoType) {
      state.userInfo = data
      DB.setUserInfoDB(data);
    }
  },
  actions: {},
  modules: {}
})
