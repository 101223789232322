import axios, { AxiosRequestConfig } from 'axios';
import { ElMessage } from "element-plus";
import * as DB from '@/utils/DB'

import * as  _URL from './api/url';
export const URL = _URL;

// tslint:disable-next-line:max-line-length

// tslint:disable-next-line:max-line-length
type AxiosRequestConfigType = AxiosRequestConfig<any> & { headers?: AxiosRequestConfig["headers"] & { Authorization?: any } }

type ResponseTypes = (
  url: string,
  method: AxiosRequestConfigType['method'],
  params?: { params?: object, data?: object },
  Config?: AxiosRequestConfigType,
) => Promise<any>

export type ResponseType = (
  url: string,
  data?: {
    params?: object,
    data?: object,
  },
  Config?: AxiosRequestConfigType,
) => Promise<any>

const defaultConfig: AxiosRequestConfigType = {
  headers: {}
}

// 请求
const request: ResponseTypes = async (url, method, params, Config) => {
  const token = DB.getUserInfoDB().token
  const Authorization = token ? `Bearer ${token}` : ''
  return await axios({
    url,
    method,
    ...params,
    ...{
      ...defaultConfig,
      headers: {
        ...defaultConfig.headers,
        ...(Authorization ? { Authorization } : {}),
      },
    },
    ...Config
  })
}

// 结果处理
async function handelAxios(send: any) {
  const { data } = await send()
  if (data.code === 0) return data
  if (data.code === 1001 || data.code === 2 || data.code === 1) ElMessage.error(data.msg)
  return data
}

export const get: ResponseType = (
  url = '', data = {}, Config = {}
) => handelAxios(() => request(url, 'GET', data, Config))

export const post: ResponseType = (
  url = '', data = {}, Config = {}
) => handelAxios(() => request(url, "POST", data, Config))






