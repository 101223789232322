import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {
  Location, User, Monitor, HomeFilled
  , Document, Menu, Setting,Message,EditPen,Comment,ChatDotSquare,Notebook
} from "@element-plus/icons-vue";

import Home from '@/views/Home.vue'
import Demo from '@/views/demo.vue'

// meta: {
//   title: '菜单名称',
//   icon: 菜单icon,
//   isHiddenHeader: 是否隐藏头部组件 默认不隐藏
//   isMenuHiddenView: 是否不在菜单栏显示路由 默认显示
//   isFaultMenuView: 是否隐藏菜单栏 默认不隐藏
// }

// export const pageRoutes: Array<RouteRecordRaw> = [
//   {
//     path: '/user',
//     name: 'user',
//     component: () => import(/* webpackChunkName: "UserManagement" */ '../views/system/UserManagement.vue'),
//     meta: {
//       icon: User,
//       title: '用户管理',
//     },
//   },
//   {
//     path: '/role',
//     name: 'role',
//     component: () => import(/* webpackChunkName: "RoleManagement" */ '../views/system/role.vue'),
//     meta: {
//       icon: Monitor,
//       title: '角色管理',
//     },
//   },
//   {
//     path: '/resource',
//     name: 'resource',
//     component: () => import(/* webpackChunkName: "ResourcesManagement" */ '../views/system/resource.vue'),
//     meta: {
//       icon: Monitor,
//       title: '资源管理',
//     },
//   },
//   {
//     path: "/log",
//     name: 'log',
//     component: () => import(/* webpackChunkName: "OperationLog" */ '../views/system/operation.vue'),
//     meta: {
//       icon: Monitor,
//       title: '操作日志',
//     },
//   },
// ]

export const pageRoutes: Array<RouteRecordRaw> = []
export const routes: Array<RouteRecordRaw> = [
  ...process.env.NODE_ENV === "development" ? [{
    path: '/demo',
    component: Demo,
    meta: {
      icon: User,
      title: 'demo',
    },
  }] : [],
  {
    path: '/',
    component: Home,
    meta: {
      title: '个人信息',
      isMenuHiddenView: true
    },
  },
  {
    meta: {
      isFaultMenuView: true,
      isHiddenHeader: true,
    },
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  }, {
    meta: {
      title: "AI Chat",
      icon: ChatDotSquare,
      isFaultMenuView: false,
      isHiddenHeader: false,
    },
    path: '/aichat/chat',
    name: 'aichat_chat',
    component: () => import(/* webpackChunkName: "aichat_index_chat" */ '../views/aichat/chat.vue'),
  }
  // },{
  //   meta: {
  //     title: "Open Ai 辅助写作",
  //     icon: EditPen,
  //     isFaultMenuView: false,
  //     isHiddenHeader: false,
  //   },
  //   path: '/openai/assistWriting',
  //   name: 'openai_assistWriting',
  //   component: () => import(/* webpackChunkName: "optlog_index_assistWriting" */ '../views/openai/assistWriting.vue'),
  // },{
  //   meta: {
  //     title: "Open Ai 大纲生成",
  //     icon: Document,
  //     isFaultMenuView: false,
  //     isHiddenHeader: false,
  //   },
  //   path: '/openai/outline',
  //   name: 'openai_outline',
  //   component: () => import(/* webpackChunkName: "outlineg_index_outline" */ '../views/openai/outline.vue'),
  // },{
  //   meta: {
  //     title: "Open Ai 评论生成",
  //     icon: Comment,
  //     isFaultMenuView: false,
  //     isHiddenHeader: false,
  //   },
  //   path: '/openai/comment',
  //   name: 'openai_comment',
  //   component: () => import(/* webpackChunkName: "comment_index_outline" */ '../views/openai/comment.vue'),
  // },{
  //   meta: {
  //     title: "Open Ai 整章美化",
  //     icon: Notebook,
  //     isFaultMenuView: false,
  //     isHiddenHeader: false,
  //   },
  //   path: '/openai/optimizeEntire',
  //   name: 'openai_optimizeEntire',
  //   component: () => import(/* webpackChunkName: "index_optimizeEntire" */ '../views/openai/optimizeEntire.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = "lalanovel管理后台"
  next()
})



export default router
