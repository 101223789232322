import { post, get, ResponseType, URL } from "@/utils/axios";

//  get   请求传  params  路径传参
//  postContent  请求传  data    body传参
export const loginApi = (params: object) => {
  return get(URL.LOGIN, { params })
}

export const loginAuthApi = (data: object): Promise<{
  code: number
  data: {
    info: UserInfoType
    token: string
  }
  msg: string
}> => post(URL.AUTH, { params: data })


// 获取网络环境
export const getENVApi = () => get(URL.SYSTEM_ENV);



// 1b5125a8f6a630959dde4842eb7a2736
