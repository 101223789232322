import { post, get, ResponseType, URL } from "@/utils/axios";
// 获取用户列表
export const getUserListApi = (data: {
  pageNo?: number
  pageSize?: number
  nickname?: string
  email?: string
  mobile?: string
  status?: DisableStatusType
}) => get(URL.ADMIN_PAGE, { params: data })

// 删除用户
export const deleteUserApi = (params: { id: number | string }) => {
  return post(URL.ADMIN_DEL, { params })
}

// 更改用户状态
export const updateStatusApi = (params: { params: object, data: object }) => {
  return post(URL.ADMIN_UPDATE_STATUS, params)
}

//根据角色获取全部用户
export const listAllUsersByRolesApi = (roles: number[]) => {
  let params = '?'
  for (let role of roles) {
    params += 'roles=' +role + '&'
  }
  if (roles.length >= 1){
    params = params.substr(0, params.length - 1)
  }
  return get(URL.ADMIN_LIST_ALL + params)
}

// 获取用户角色
export const getUserRoleApi = (userId: number) => get(URL.ROLE_USER, { params: { userId } })

// 获取所有角色
export const getAllRoleApi = (userId: number) => get(URL.ROLE_USER_LIST_ALL, { params: { userId } })
// 获取角色列表
export const getRoleListApi = (params: {
  pageNo?: number
  pageSize?: number
  type?: AdministratorType
  name?: string
  status?: DisableStatusType
}) => get(URL.ROLE_PAGE, { params })

// 删除角色
export const delRoleApi = (id: number | number[]) => post(URL.ROLE_DEL, { data: { id } })

// 添加角色  
export const createRoleApi = (
  { data }: { data: CreateRoleReq }
) => post(URL.ROLE_CREATE, { data })
// 更新角色
export const updateRoleApi = (
  { data, params }: { data: CreateRoleReq, params: { id: number } }
) => post(URL.ROLE_UPDATE, { data, params })

// 添加资源
export const createResourcesApi = (
  data: ResourcesType
) => post(URL.RESOURCE_CREATE, { data })

// 更新资源
export const updateResourceApi = ({
  data, params
}: {
  data: ResourcesType
  params: { id: number }
}) => post(URL.RESOURCE_UPDATE, { data, params })

// 获取全部资源
export const getAllResourcesApi = () => get(URL.RESOURCE_TREE_ALL)

// 根据id 获取资源
export const queryResourcesApi = (params: { pid: number, isDirect: boolean }) => {
  return get(URL.RESOURCE_LIST_CHILD, { params })
}
// 删除
export const delResourceApi = (id: number | number[]) => {
  return post(URL.RESOURCE_DEL, { data: { id } })
}

// 获取操作日志
export const getOperationLogApi = (params: LogType) => {
  return get(URL.OPERATION_PAGE, { params })
}

// 分配角色权限
export const assignRolePermissionsApi = ({ userId, data }: { userId: number, data: any[] }) => {
  console.log(data)
  return post(URL.ROLE_USER_UPDATE, {
    params: { userId },
    data
  })
}

// 分配资源权限 
export const assignResourcePermissions = ({
  roleId,
  data
}: { roleId: number, data: Array<{ resourceId: number, select: true }> }) => {
  return post(URL.RESOURCE_ROLE_UPDATE, {
    data,
    params: { roleId }
  })
}

// 获取角色权限   /resource/role/tree/all
export const getRolePermissionsApi = (roleId: number) => {
  return get(URL.RESOURCE_ROLE_TREE_ALL, { params: { roleId } })
}

// 获取路由 
export const getRouterListApi = (userId: number) => {
  return get(URL.RESOURCE_USER_TREE_DIV_MENU, {
    params: { userId }
  })
}

