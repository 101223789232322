// const baseUrl = process.env.NODE_ENV === "development" ? 'https://api.lalanovel.com/admin' : 'https://api.lalanovel.com/admin';
const baseUrl = process.env.NODE_ENV === "development" ? 'http://localhost:80' : 'https://api.lalanovel.com/admin';
// const baseUrl = 'https://api.lalanovel.com/admin';


export const SYSTEM_ENV = baseUrl + '/system/env';
export const AUTH = baseUrl + '/auth';
export const LOGIN = baseUrl + '/login';


// 广告页
export const AD_TREE_ALL = baseUrl + '/ad/tree/all';
export const AD_CONFIG_COPY = baseUrl + "/ad/config/copy";
export const AD_CONFIG_DEL = baseUrl + "/ad/config/del";
export const AD_POSITION_DEL = baseUrl + "/ad/position/del";
export const AD_ITEM_DEL = baseUrl + "/ad/item/del";
export const AD_LIST_ENUM = baseUrl + "/ad/list/enum";
export const AD_CONFIG_ADD = baseUrl + "/ad/config/add";
export const AD_POSITION_ADD = baseUrl + "/ad/position/add";
export const AD_ITEM_ADD = baseUrl + "/ad/item/add";
export const AD_CONFIG_MODIFY = baseUrl + "/ad/config/modify";
export const AD_POSITION_UPDATE = baseUrl + "/ad/position/update";
export const AD_ITEM_UPDATE = baseUrl + "/ad/item/update";


//
export const ADMIN_PAGE = baseUrl + '/admin/page';
export const ADMIN_DEL = baseUrl + '/admin/del';
export const ADMIN_UPDATE_STATUS = baseUrl + '/admin/update_status';
export const ADMIN_LIST_ALL = baseUrl + '/admin/list-all';


export const ROLE_USER = baseUrl + '/role/user';
export const ROLE_USER_UPDATE = baseUrl + '/role/user/update';
export const ROLE_PAGE = baseUrl + '/role/page';
export const ROLE_CREATE = baseUrl + '/role/create';
export const ROLE_UPDATE = baseUrl + '/role/update';
export const ROLE_DEL = baseUrl + '/role/del';
export const ROLE_USER_LIST_ALL = baseUrl + '/role/user/list/all';


export const RESOURCE_CREATE = baseUrl + '/resource/create';
export const RESOURCE_UPDATE = baseUrl + '/resource/update';
export const RESOURCE_DEL = baseUrl + '/resource/del';
export const RESOURCE_TREE_ALL = baseUrl + '/resource/tree/all';
export const RESOURCE_LIST_CHILD = baseUrl + '/resource/list/child';
export const RESOURCE_ROLE_UPDATE = baseUrl + '/resource/role/update';
export const RESOURCE_ROLE_TREE_ALL = baseUrl + '/resource/role/tree/all';
export const RESOURCE_USER_TREE_DIV_MENU = baseUrl + '/resource/user/tree/div_menu';


export const OPERATION_PAGE = baseUrl + '/operation/page';


/**
 * 安审——初审
 */
export const AUDIT_CHAPTER_CONTENT = baseUrl + '/audit/chapterContent';
export const AUDIT_BLOCK_CONTENT = baseUrl + '/audit/blockContent';
export const AUDIT_REJECT_REASON_SET = baseUrl + '/audit/rejectReasonSet'
export const AUDIT_MACHINE_REJECT_REASON_SET = baseUrl + '/audit/getMachineAuditRejectReason'
export const AUDIT_CONFIG_MAP = baseUrl + '/audit/getConfigMap'
export const AUDIT_FIRST_PASS = baseUrl + '/audit/firstPass';
export const AUDIT_FIRST_REJECT = baseUrl + '/audit/firstReject';
export const AUDIT_FIRST_UNDETEMINED = baseUrl + '/audit/firstUndetermined';
export const AUDIT_REJECT_IMAGE = baseUrl + '/audit/rejectImage';
export const AUDIT_IGNORE = baseUrl + '/audit/ignore';
export const RE_AUDIT_BATCH_FIRST_PASS = baseUrl + '/audit/batchFirstPass'
export const AUDIT_LIST = baseUrl + '/audit/auditList/';
/**
 * 安审——复审
 */
export const RE_AUDIT_LIST = baseUrl + '/reAudit/list';
export const RE_AUDIT_GET = baseUrl + '/reAudit/get';
export const RE_AUDIT_GET_QUERY_CONFIG = baseUrl + '/reAudit/getQueryConfig';
export const RE_AUDIT_AGAIN_PASS = baseUrl + '/reAudit/againPass'
export const RE_AUDIT_AGAIN_NO_OBJECTION = baseUrl + '/reAudit/noObjection'
export const RE_AUDIT_BATCH_AGAIN_PASS = baseUrl + '/reAudit/batchAgainPass'
export const RE_AUDIT_BATCH_NO_OBJECTION = baseUrl + '/reAudit/batchAgainNoObjection'
export const RE_AUDIT_AGAIN_REJECT = baseUrl + '/reAudit/againReject'
export const RE_AUDIT_AGAIN_UNDETERMINED = baseUrl + "/reAudit/againUndetermined";
export const AUDIT_COUNT = baseUrl + "/audit/auditCount";
export const AUDITOR_LIST = baseUrl + "/audit/auditorList";
export const AUDIT_PERMISSIONS_LIST = baseUrl + "/audit/auditPermissionsList";
export const UPDATE_AUDITOR = baseUrl + "/audit/updateAuditor";
export const EXPORT_AUDIT_COUNT = baseUrl + "/audit/exportAuditCount";
export const AUDIT_REVOKE_RECORD = baseUrl + "/audit/auditRevokeRecord";
export const AUDIT_RECORD = baseUrl + "/audit/auditRecordList";
export const SOURCE_TYPE = baseUrl + "/audit/getSourceType";
export const REAL_AUDIT_COUNT = baseUrl + "/audit/getRealAuditCount";

//编辑-书籍更新查询相关
export const EDITOR_BOOK_UPDATE_MONITORING_BOOK_CATEGORIES = baseUrl + "/book-update-monitoring/book-categories";
export const EDITOR_BOOK_UPDATE_MONITORING_LIST = baseUrl + "/book-update-monitoring/list";
export const EDITOR_BOOK_UPDATE_MONITORING_DELETE = baseUrl + "/book-update-monitoring/delete"
export const EDITOR_BOOK_UPDATE_MONITORING_TRUNCATE = baseUrl + "/book-update-monitoring/truncate"
export const EDITOR_BOOK_UPDATE_MONITORING_IMPORT = baseUrl + "/book-update-monitoring/import"
export const EDITOR_BOOK_UPDATE_MONITORING_EXPORT_INFO = baseUrl + "/book-update-monitoring/export-info"

//福利-书籍评级相关
export const WELFARE_BOOK_RATING_LEVELS = baseUrl + "/book-rating/levels"
export const WELFARE_BOOK_RATING_MONTHS = baseUrl + "/book-rating/months"
export const WELFARE_BOOK_RATING_LIST = baseUrl + "/book-rating/list"
export const WELFARE_BOOK_RATING_DELETE = baseUrl + "/book-rating/delete"
export const WELFARE_BOOK_RATING_PARSE = baseUrl + "/book-rating/parse"
export const WELFARE_BOOK_RATING_IMPORT = baseUrl + "/book-rating/import"
export const WELFARE_BOOK_RATING_TEMPLATE = baseUrl + "/book-rating/template"

//作品-书籍相关
export const WORKS_BOOK_LIST = baseUrl + "/book/list"
export const WORKS_BOOK_GET = baseUrl + "/book/get"
export const WORKS_AUTHOR_INFO_GET = baseUrl + "/book/getAuthorInfo"
export const WORKS_BOOK_UPDATE = baseUrl + "/book/update"
export const WORKS_BOOK_ADD_MARK = baseUrl + "/book/addMark"
export const WORKS_BOOK_ADD_POPULARITY = baseUrl + "/book/addPopularity"
export const WORKS_BOOK_GET_OPTION = baseUrl + "/book/getOptions"
export const WORKS_BOOK_DELETE_MARK = baseUrl + "/book/deleteMark"
export const WORKS_BOOK_MODIFY_AUTHOR = baseUrl + "/book/modify-book-author"

//碎片
export const FRAGMENT_LIST = baseUrl + "/fragment/list"
export const FRAGMENT_GET = baseUrl + "/fragment/get"
export const FRAGMENT_UPDATE = baseUrl + "/fragment/update"
//举报
export const REPORT_LIST = baseUrl + "/report/list"
export const REPORT_PASS = baseUrl + "/report/pass"
export const REPORT_FORBID = baseUrl + "/report/forbid"
export const REPORT_OPTIONS = baseUrl + "/report/options"

//章节信息
export const CHAPTER_INFO_SEARCH = baseUrl + "/chapter-info/search"

//openai
export const OPENAI_CHAT_LONGTEXT = baseUrl + "/openai/chat/longtext"
export const OPENAI_CHAT_SEND = baseUrl + "/openai/chat/send"
export const OPENAI_CHAT_HISTORY = baseUrl + "/openai/chat/history"
export const OPENAI_CHAT_CLEAR = baseUrl + "/openai/chat/clear"
export const OPENAI_CHAT_beautifyParagraph = baseUrl + "/openai/chat/beautifyParagraph"
export const OPENAI_CHAT_continueWriting = baseUrl + "/openai/chat/continueWriting"
export const OPENAI_CHAT_summary = baseUrl + "/openai/chat/summary"
export const OPENAI_CHAT_outline = baseUrl + "/openai/chat/outline"
export const OPENAI_CHAT_comment = baseUrl + "/openai/chat/comment"
export const OPENAI_CHAT_optimizeEntire = baseUrl + "/openai/chat/optimizeEntire"


export const AI_CHAT_SEND = baseUrl + "/aichat/send"
export const AI_CHAT_LONGTEXT = baseUrl + "/aichat/longtext"

