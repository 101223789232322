

const CleanList = ["token", "userInfo", 'router'];

export const CleanLoginInfoDB = () => {
  CleanList.forEach((key) => {
    localStorage.removeItem(key)
  })
}

export const setUserInfoDB = (data: UserInfoType): void => {
  localStorage.setItem("userInfo", JSON.stringify(data));
};
export const getUserInfoDB = (): UserInfoType => {
  return JSON.parse(localStorage.getItem("userInfo") ?? "{}");
};


export const setRouterDB = (data: any[]): void => {
  localStorage.setItem("router", JSON.stringify(data));
};
export const getRouterDB = (): any[] => {
  return JSON.parse(localStorage.getItem("router") || "[]");
};



