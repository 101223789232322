
export default {
  "/resource": () => import(/* webpackChunkName: "resource" */ "@/views/system/resource.vue"),
  "/role": () => import(/* webpackChunkName: "role" */ "@/views/system/role.vue"),
  "/admin_user": () => import(/* webpackChunkName: "UserManagement" */ "@/views/system/UserManagement.vue"),
  "/operation": () => import(/* webpackChunkName: "operation" */ "@/views/system/operation.vue"),
  "/ad_config": () => import(/* webpackChunkName: "AdConfig" */ "@/views/AdPage/AdConfig.vue"),
  "/audit/chapterContent": () => import(/* webpackChunkName: "audit_chapterContent" */ "@/views/audit/chapterContent.vue"),
  "/audit/reAudit": () => import(/* webpackChunkName: "audit_reAudit" */ "@/views/audit/reAudit.vue"),
  "/audit/auditCount": () => import(/* webpackChunkName: "audit_count" */ "@/views/audit/auditCount.vue"),
  "/audit/auditPermissions": () => import(/* webpackChunkName: "audit_permissions" */ "@/views/audit/auditPermissions.vue"),
  "/audit/auditRecord": () => import(/* webpackChunkName: "audit_record" */ "@/views/audit/auditRecord.vue"),
  "/audit/auditAuthorSay": () => import(/* webpackChunkName: "audit_authorSay" */ "@/views/audit/authorSay.vue"),
  "/audit/cosplay": () => import(/* webpackChunkName: "audit_cosplay" */ "@/views/audit/cosplay.vue"),
  "/audit/bookComment": () => import(/* webpackChunkName: "audit_bookComment" */ "@/views/audit/bookComment.vue"),
  "/audit/bookName": () => import(/* webpackChunkName: "audit_bookName" */ "@/views/audit/bookName.vue"),
  "/audit/communitySeries": () => import(/* webpackChunkName: "audit_bookName" */ "@/views/audit/communitySeries.vue"),
  "/audit/bookIntroduce": () => import(/* webpackChunkName: "audit_bookIntroduce" */ "@/views/audit/bookIntroduce.vue"),
  "/audit/postContent": () => import(/* webpackChunkName: "audit_postContent" */ "@/views/audit/postContent.vue"),
  "/audit/postComment": () => import(/* webpackChunkName: "audit_postComment" */ "@/views/audit/postComment.vue"),
  "/audit/auditWritingGroupMessage": () => import(/* webpackChunkName: "audit_writingGroupMessage" */ "@/views/audit/writingGroupMessage.vue"),
  "/editor/bookUpdateMonitoring": () => import(/* webpackChunkName: "editor_bookUpdateMonitoring" */ "@/views/editor/bookUpdateMonitoring/index.vue"),
  "/welfare/bookRating": () => import(/* webpackChunkName: "editor_bookRating" */ "@/views/welfare/bookRating/index.vue"),
  "/book/bookList": () => import(/* webpackChunkName: "works_book" */ "@/views/book/bookList.vue"),
  "/book/book": () => import(/* webpackChunkName: "works_book" */ "@/views/book/book.vue"),
  "/fragment/index": () => import(/* webpackChunkName: "fragment_index" */ "@/views/fragment/index.vue"),
  "/report/index": () => import(/* webpackChunkName: "report_index" */ "@/views/report/index.vue"),
  "/chapter": () => import(/* webpackChunkName: "works_chapter" */ "@/views/chapter/chapter.vue"),
  "/book/chapter-info": () => import(/* webpackChunkName: "works_chapter" */ "@/views/book/chapterInfo/index.vue"),
  "/book/book-transfer": () => import(/* webpackChunkName: "works_chapter" */ "@/views/book/bookTransfer/index.vue"),
  "/openai/chat": () => import(/* webpackChunkName: "openai_chat" */ "@/views/openai/chat.vue"),
  "/openai/assistWriting": () => import(/* webpackChunkName: "openai_assistWriting" */ "@/views/openai/assistWriting.vue"),
  "/openai/outline": () => import(/* webpackChunkName: "openai_outline" */ "@/views/openai/outline.vue"),
  "/openai/comment": () => import(/* webpackChunkName: "openai_comment" */ "@/views/openai/comment.vue"),
  "/openai/optimizeEntire": () => import(/* webpackChunkName: "openai_optimizeEntire" */ "@/views/openai/optimizeEntire.vue"),
}

