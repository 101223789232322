import emoji from "node-emoji";

export const MergeFun = () => {
    let timeOut: number = Number(new Date());
    let fn: any = null;
    const t = 100;
    return (handle: () => void) => {
        const time = Number(new Date());
        fn = handle;
        setTimeout(() => {
            if (time - timeOut > t) {
                timeOut = time;
                fn();
            }
        }, t)
    };
}


// tree 组装
export const treeAssemble = (tree: any[]) => {
    const list = [...tree];
    const node = tree.filter((item, i) =>
        item.pid === 0 ? list.splice(i, 1) : false
    );

    function recursion(list: any[], current: any) {
        list.forEach((item) => {
            if (current.id === item.pid) {
                (current.children ||= []).push(item);
                recursion(list, current.children);
            }
        });
        return current;
    }

    return node.map((item) => recursion(list, item));
}

// 过滤 undefined
(Array.prototype as ArrayPrototype).filterMap = function (callBack) {
    const arr: any[] = [];
    (this as unknown as any[]).forEach((item: any, index: number, This: any[]) => {
        const result: any = callBack(item, index, This);
        if (result !== undefined) arr.push(result)
    });
    return arr
};


interface ObjectToStringParamsType {
    [k: string]: string | number | boolean | null | undefined;
}

export function ObjectToString(params: ObjectToStringParamsType, str = '?'): string {
    for (const k in params) {
        if (params[k] !== undefined) str += `${k}=${params[k]}&`;
    }
    return str.slice(0, -1);
}

export function getImageUrl(url: string = 'https://piccdn.lalanovel.com/', w?: number): string {
    let defaultURL = "https://piccdn.lalanovel.com/";
    if (url) {
        // 拼接为完整的url
        url = url.indexOf("http") === 0 ? url : defaultURL + url;
        // 添加裁剪
        const splitStr = url.split("?");
        defaultURL = splitStr[0];
        let params: string | Array<string> = splitStr[1];
        if (params) params = params.split("&");
        else if (w) return `${defaultURL}?x-oss-process=image/resize,w_${w}`
        else return url;
        const obj: { [k: string]: any } = {};
        // tslint:disable-next-line:forin
        for (const k in [...params]) {
            obj[k] = params[k]
        }
        const oss = obj["x-oss-process"];
        obj["x-oss-process"] = w
            ? `image/resize,w_${w}`
            : oss ?? `image/format,webp`;
        defaultURL += ObjectToString(obj);
        return defaultURL;
    } else return "";
}


/**
 * 解析章节内容
 */
export function ConversionContent(
    params: string,
    roleList: any[],
    formatType: string = "name"
) {
    const parseRoleMap: { [k: string]: any } = []

    const textList =
        emoji.emojify(params || "").split(/\n/g)
    // 角色对象
    const objId: { [k: string]: any } = {};
    const objName: { [k: string]: any } = {};
    // 遍历角色列表 添加到角色对象中
    roleList.forEach((item) => {
        objId[item[formatType]] = item;
    });
    roleList.forEach((item) => {
        objName[item.name] = item;
    });

    const imgReg = /(\[img:)(.*)((.jpeg|.jpg|.png|.gif|.webp)\])$/
    const nodeList = textList.map((item, i) => {
        const regId = /^(^[\#]*)(\#{1,2})(\s?)([^\ ]+?)[ ]+([^\r\n]+)/gi;
        item = item.trim()
        const str = regId.exec(String(item));
        if (str) {
            let roleInfo = objId[str[4]];
            if (!roleInfo) {
                roleInfo = objName[str[4]];
            }
            let type = str[2] === '##' ? "LEAD" : "COSTAR"

            if(str[4].indexOf('<') !== -1){
                return {type: "text", content: item};
            }
            const parsed = parseRoleMap.includes(roleInfo)
            if (parsed) {
                type = 'againRole' + type;
            }

            parseRoleMap.push(roleInfo)
            const img = imgReg.exec(str[5])
            if (img) {
                const originalUrl = img[2] + img[4];
                const url = getImageUrl(originalUrl, 1800);
                return {type, content: str[5].replace(img[0], ''), url, roleInfo, originalUrl};
            }
            return {type, content: str[5], roleInfo};

        }

        const img = imgReg.exec(item)
        if (img) {
            const originalUrl = img[2] + img[4];
            const url = getImageUrl(originalUrl, 1800)
            return {type: "img", content: url, originalUrl};
        }
        return {type: "text", content: item};
    });
    console.log('nodeList', nodeList)
    return nodeList;
}


// 拼接路径
export function fixPath(path: string): string {
    const str = path.replace(/^\/\/+/, "/");
    return str[0] === "/"
        ? str[1]
            ? str
            : ""
        : ("/" + str).replace(/^\/\/+/, "/");
}

// 获取完整路由路径
export function getCompletePath(currentPath: string, sourcePath?: string): string {
    const path = currentPath ? fixPath(currentPath) : "";
    const completePath = (
        currentPath === "/"
            ? "/"
            : currentPath[0] === "/" && currentPath[1]
                ? currentPath
                : (sourcePath ?? "") + path
    ).replace(/^\/\/+/, "/");
    return completePath
}


export function ArrayMap<T>(arr: anyObject[], k: keyof anyObject, v: keyof anyObject): object {
    const obj: anyObject = {}
    arr?.forEach((item) => obj[item[k]] = item[v])
    return obj
}

export function cloneObj<T>(obj: any) {
    var newObj: any = {};
    if (obj instanceof Array) {
        newObj = [];
    }
    for (var key in obj) {
        var val = obj[key];
        newObj[key] = typeof val === 'object' ? cloneObj(val) : val;
    }
    return newObj;
}

export function getMaxStr(str: string, max: number) {
    return str.length > max ? str.substr(0, max) + "..." : str
}

/**
 * 求出一个数字在数组中的位置
 * @param arr
 * @param num
 */
export function getIndexToIns(arr: number[], num: number): number {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === num) {
            return i;
        }
    }
    return -1;
}

/**
 * 把集合平分
 * @param arr
 * @param num
 */
export function chunk(arr: any, num: number) {
    num = num * 1 || 1;
    var ret: any = [];
    arr.forEach(function (item: any, i: number) {
        if (i % num === 0) {
            ret.push([]);
        }
        ret[ret.length - 1].push(item);
    });
    return ret;
};
// run

export const defaultPicURL = "https://piccn.ihuaben.com/";


